import Component from '../../../assets/scripts/modules/component'

class MultipleObjectViewersComponent extends Component {
  init () {
    this.buttonOpen = this.element.querySelector('.button--open')
    this.viewers = [...this.element.querySelectorAll('.object-viewer')]
    this.thumbnails = this.element.querySelector('.thumbnail-list')
    this.eventPrefix = this.thumbnails?.getAttribute('data-event-prefix') || ''

    this.currentViewerIndex = 0

    if (this.viewers.length) {
      this.viewers.forEach(viewer => (document.querySelector('.container--overlays') || document.body).appendChild(viewer))
      this.buttonOpen?.addEventListener('click', () => this.onOpenViewerClick())
      window.addEventListener(`${this.eventPrefix ? this.eventPrefix + '-' : ''}thumbnail-click`, event => this.onThumbnailClick(event))
    }
  }

  onOpenViewerClick () {
    this.toggleViewer()
    window.dispatchEvent(new CustomEvent(`${this.eventPrefix ? this.eventPrefix + '-' : ''}activate-thumbnail`, { detail: { index: this.currentViewerIndex } }))
  }

  toggleViewer (forceClose = null) {
    if (!this.viewers[this.currentViewerIndex]) {
      return
    }

    const isOpen = forceClose ?? this.viewers[this.currentViewerIndex].classList.contains('object-viewer--active')

    if (isOpen) {
      this.viewers[this.currentViewerIndex].classList.remove('object-viewer--active')
      document.documentElement.classList.remove('prevent-scrolling')
      document.documentElement.classList.remove('is-fullscreen')
      this.viewers[this.currentViewerIndex].dispatchEvent(new CustomEvent('pause'))
    } else {
      this.viewers[this.currentViewerIndex].classList.add('object-viewer--active')
      document.documentElement.classList.add('prevent-scrolling')
      document.documentElement.classList.add('is-fullscreen')
      this.viewers[this.currentViewerIndex].dispatchEvent(new CustomEvent('resume'))
    }
  }

  onThumbnailClick (event) {
    if (!this.viewers[event.detail.index]) {
      return
    }

    this.toggleViewer(true)
    this.currentViewerIndex = event.detail.index
    this.toggleViewer()
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.multiple-object-viewers').forEach((element) => {
  element.instance = element.instance || new MultipleObjectViewersComponent(element)
}))
