import Component from '../../../assets/scripts/modules/component'

class ContentExpander extends Component {
  init () {
    this.button = this.element.querySelector('.button--expand')
    this.region = this.element.querySelector('.content-expander__extra-content')

    if (!this.button || !this.region) {
      return
    }

    this.expandLabel = this.element.getAttribute('data-expand-label')
    this.contractLabel = this.element.getAttribute('data-contract-label')

    this.button.addEventListener('click', () => this.toggleRegion())
  }

  toggleRegion () {
    if (!this.button || !this.region) {
      return
    }

    if (this.button.getAttribute('aria-expanded') === 'true') {
      this.region.setAttribute('hidden', 'hidden')
      this.region.setAttribute('aria-hidden', true)
      this.button.setAttribute('aria-expanded', false)
      this.element.classList.remove('content-expander--expanded')

      this.button.querySelector('.button__span').innerText = this.expandLabel
    } else {
      this.region.removeAttribute('hidden')
      this.region.setAttribute('aria-hidden', false)
      this.button.setAttribute('aria-expanded', true)
      this.element.classList.add('content-expander--expanded')

      this.button.querySelector('.button__span').innerText = this.contractLabel

      const rect = this.region.getBoundingClientRect()
      window.scrollTo({ top: window.pageYOffset + rect.top - window.innerHeight / 3, left: 0, behavior: 'smooth' })
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.content-expander').forEach((element) => {
  element.instance = element.instance || new ContentExpander(element)
}))
