
import breakpoints from '../../../assets/scripts/modules/breakpoints'
import Component from '../../../assets/scripts/modules/component'
import TitleTween from '../../../assets/scripts/modules/title-tween'

class ObjectDetailComponent extends Component {
  init () {
    this.title = this.element.querySelector('.object-detail-intro__title')

    if (this.title) {
      this.tween = new TitleTween({ element: this.title, wrapper: this.element, start: 1, end: 2, startTrigger: breakpoints.isLandscape() ? 'top top' : 'bottom bottom' })
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.object-detail-intro').forEach(element => {
  element.instance = element.instance || new ObjectDetailComponent(element)
}))
