
import Component from '../../../assets/scripts/modules/component'

class ThumbnailListComponent extends Component {
  init () {
    this.thumbnails = [...this.element.querySelectorAll('.thumbnail-list__thumbnail')]
    this.eventPrefix = this.element.getAttribute('data-event-prefix') || ''

    this.thumbnails.forEach((thumbnail, index) => thumbnail.addEventListener('click', () => this.onClickHandler(index, thumbnail)))
    window.addEventListener(`${this.eventPrefix ? this.eventPrefix + '-' : ''}activate-thumbnail`, event => this.activateThumbnail(event.detail.index))
    window.addEventListener(`${this.eventPrefix ? this.eventPrefix + '-' : ''}reset-thumbnails`, () => this.resetThumbnails())
  }

  onClickHandler (index, thumbnail) {
    document.activeElement?.blur()

    window.dispatchEvent(new CustomEvent(`${this.eventPrefix ? this.eventPrefix + '-' : ''}thumbnail-click`, { detail: { index, thumbnail } }))
    window.dispatchEvent(new CustomEvent(`${this.eventPrefix ? this.eventPrefix + '-' : ''}activate-thumbnail`, { detail: { index } }))
  }

  activateThumbnail (index) {
    this.resetThumbnails()
    this.thumbnails[index]?.classList.add('thumbnail-list__thumbnail--active')
  }

  resetThumbnails () {
    this.thumbnails.forEach(thumbnail => thumbnail.classList.remove('thumbnail-list__thumbnail--active'))
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.thumbnail-list').forEach(element => {
  element.instance = element.instance || new ThumbnailListComponent(element)
}))
