import { createFocusTrap } from 'focus-trap'
import Component from '../../../assets/scripts/modules/component'

class PanoramaViewerComponent extends Component {
  init () {
    this.container = this.element.querySelector('.panorama-viewer__container')
    this.imageUrl = this.element.getAttribute('data-image-url') || ''
    this.title = this.element.querySelector('.panorama-viewer__title')
    this.buttonInfo = this.element.querySelector('.button--info')
    this.buttonClose = this.element.querySelector('.button--close')
    this.lazyload = this.element.getAttribute('data-lazyload')

    if (!this.container || !this.imageUrl) {
      return
    }

    this.translations = {
      twoFingers: this.element.getAttribute('data-label-two-fingers') || '',
      loadError: this.element.getAttribute('data-label-load-error') || ''
    }

    this.started = false

    this.focusTrap = createFocusTrap(this.container.closest('.panorama-viewer'))

    this.start()
  }

  start () {
    if (this.started) {
      return
    }

    if (!this.lazyload) {
      this.initPanorama()
      this.viewer?.startKeyboardControl()
      this.focusTrap.activate()

      return
    }

    const onStartHandler = () => {
      if (this.started) {
        onResumeHandler()
        return
      }

      this.initPanorama()
      this.viewer?.startKeyboardControl()
      this.focusTrap.activate()

      this.started = true
    }

    const onResetHandler = () => {
      this.viewer?.stopKeyboardControl()
      this.focusTrap.deactivate()
      document.documentElement.classList.remove('prevent-scrolling')
      document.documentElement.classList.remove('is-fullscreen')
    }

    const onPauseHandler = () => {
      this.focusTrap.deactivate()
    }

    const onResumeHandler = () => {
      if (!this.started) {
        onStartHandler()
        return
      }

      this.focusTrap.activate()
    }

    const onMouseDownHandler = () => {
      this.element.classList.add('panorama-viewer--grabbing')
      this.buttonInfo?.classList.remove('button--visible')
      this.title?.classList.remove('panorama-viewer__title--visible')
    }

    const onMouseUpHandler = () => {
      this.element.classList.remove('panorama-viewer--grabbing')
    }

    const onTouchStartHandler = () => {
      this.element.classList.add('panorama-viewer--grabbing')
      this.buttonInfo?.classList.remove('button--visible')
      this.title?.classList.remove('panorama-viewer__title--visible')
    }

    const onTouchEndHandler = () => {
      this.element.classList.remove('panorama-viewer--grabbing')
    }

    this.element.addEventListener('start', onStartHandler)
    this.element.addEventListener('reset', onResetHandler)
    this.element.addEventListener('pause', onPauseHandler)
    this.element.addEventListener('resume', onResumeHandler)
    this.element.addEventListener('mousedown', onMouseDownHandler)
    this.element.addEventListener('mouseup', onMouseUpHandler)
    this.element.addEventListener('touchstart', onTouchStartHandler)
    this.element.addEventListener('touchend', onTouchEndHandler)

    // this.element.addEventListener('init-accessibility', () => this.focusTrap.activate())
    // this.element.addEventListener('enable-keyboard', () => this.viewer?.startKeyboardControl())
    // this.element.addEventListener('disable-keyboard', () => this.viewer?.stopKeyboardControl())
  }

  async initPanorama () {
    const Viewer = (await import('../../../assets/scripts/plugins/photo-sphere-viewer')).default()

    const options = {
      container: this.container,
      panorama: this.imageUrl,
      downloadUrl: '',
      lang: this.translations,
      loadingImg: '/static/images/logo.white.svg',
      loadingTxt: '',
      keyboard: 'always',
      mousewheel: false,
      mousemove: true,
      mousewheelCtrlKey: false,
      touchmoveTwoFingers: false,
      navbar: [],
      keyboardActions: {
        ArrowUp: 'ROTATE_UP',
        ArrowDown: 'ROTATE_DOWN',
        ArrowRight: 'ROTATE_RIGHT',
        ArrowLeft: 'ROTATE_LEFT',
        PageUp: 'ZOOM_IN',
        PageDown: 'ZOOM_OUT',
        '+': 'ZOOM_IN',
        '-': 'ZOOM_OUT',
        Escape: () => this.closePanorama()
      }
    }

    this.viewer = new Viewer(options)

    this.buttonInfo?.classList.add('button--visible')
    this.title?.classList.add('panorama-viewer__title--visible')

    this.buttonClose?.addEventListener('click', () => this.onButtonCloseClickHandler())
  }

  onButtonCloseClickHandler () {
    if (this.element.classList.contains('panorama-viewer--active')) {
      this.closePanorama()
    } else {
      this.openPanorama()
    }
  }

  closePanorama () {
    if (!this.element.classList.contains('panorama-viewer--active')) {
      return
    }

    this.element.classList.remove('panorama-viewer--active')
    document.documentElement.classList.remove('prevent-scrolling')
    document.documentElement.classList.remove('is-fullscreen')
    this.viewer?.stopKeyboardControl()
    this.focusTrap?.deactivate()
  }

  openPanorama () {
    if (this.element.classList.contains('panorama-viewer--active')) {
      return
    }

    this.element.classList.add('panorama-viewer--active')
    document.documentElement.classList.add('prevent-scrolling')
    document.documentElement.add.remove('is-fullscreen')
    this.viewer?.startKeyboardControl()
    this.focusTrap?.activate()
  }
}

window.addEventListener('init-after-load', () => document.querySelectorAll('.panorama-viewer').forEach(element => {
  element.instance = element.instance || new PanoramaViewerComponent(element)
}))
