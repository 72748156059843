import breakpoints from '../../../assets/scripts/modules/breakpoints'
import Component from '../../../assets/scripts/modules/component'
import TitleTween from '../../../assets/scripts/modules/title-tween'

class PlacesMapComponent extends Component {
  init () {
    this.title = this.element.querySelector('.places-map__title')

    if (this.title) {
      this.tween = new TitleTween({ element: this.title, wrapper: this.element, start: 2, end: 1, endTrigger: 'bottom bottom' })
    }

    this.buttonZoomIn = this.element.querySelector('.places-map__zoom-button--in')
    this.buttonZoomOut = this.element.querySelector('.places-map__zoom-button--out')
    this.svg = this.element.querySelector('.places-map__svg')

    if (this.buttonZoomIn) {
      this.buttonZoomIn.addEventListener('click', () => {
        if (this.element.classList.contains('places-map--zoomed-in')) {
          //
        } else {
          this.element.classList.add('places-map--zoomed-in')
          this.buttonZoomIn.disabled = true
          this.buttonZoomOut.disabled = false
        }
      })
    }

    if (this.buttonZoomOut) {
      this.buttonZoomOut.addEventListener('click', () => {
        if (this.element.classList.contains('places-map--zoomed-in')) {
          this.element.classList.remove('places-map--zoomed-in')
          this.buttonZoomOut.disabled = true
          this.buttonZoomIn.disabled = false
        } else {
          //
        }
      })
    }

    window.addEventListener('resize', () => this.onResize())
    this.onResize()
  }

  onResize () {
    if (!this.svg) {
      return
    }

    this.svg.setAttribute('viewBox', breakpoints.isLandscape() ? '0 0 2783 1785' : '250 0 2783 1785')
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.places-map').forEach((element) => {
  element.instance = element.instance || new PlacesMapComponent(element)
}))
