
import Component from '../../../assets/scripts/modules/component'
import TitleTween from '../../../assets/scripts/modules/title-tween'

class HeroTextComponent extends Component {
  init () {
    this.title = this.element.querySelector('.hero-text__title')

    if (this.title) {
      this.tween = new TitleTween({ element: this.title, wrapper: this.element, start: 1, end: 2, startTrigger: 'top top' })
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.hero-text').forEach(element => {
  element.instance = element.instance || new HeroTextComponent(element)
}))
