import Component from '../../../assets/scripts/modules/component'
import TitleTween from '../../../assets/scripts/modules/title-tween'

class RelevantCardsComponent extends Component {
  init () {
    this.title = this.element.querySelector('.relevant-cards__title')

    if (this.title) {
      this.tween = new TitleTween({ element: this.title, wrapper: this.element, start: 2, end: 1, endTrigger: 'bottom bottom' })
    }

    this.addEvents()
  }

  addEvents () {
    this.button = this.element.querySelector('.relevant-cards__load-more .button')
    this.region = this.element.querySelector('.relevant-cards__disclosure-content-container')

    if (!this.button || !this.region) {
      return
    }

    this.button.addEventListener('click', () => this.toggleRegion())
  }

  toggleRegion () {
    if (!this.button || !this.region) {
      return
    }

    if (this.button.getAttribute('aria-expanded') === 'true') {
      this.region.setAttribute('hidden', 'hidden')
      this.region.setAttribute('aria-hidden', true)
      this.button.setAttribute('aria-expanded', false)
      this.element.classList.remove('mah-disclosure--expanded')
    } else {
      this.button.style.display = 'none' // hide btn

      this.region.removeAttribute('hidden')
      this.region.setAttribute('aria-hidden', false)
      this.button.setAttribute('aria-expanded', true)
      this.element.classList.add('mah-disclosure--expanded')

      const rect = this.region.getBoundingClientRect()

      setTimeout(() => {
        window.scrollTo({ top: window.pageYOffset + rect.top - window.innerHeight / 3, left: 0, behavior: 'smooth' })
      }, 1)
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.relevant-cards').forEach((element) => {
  element.instance = element.instance || new RelevantCardsComponent(element)
}))
