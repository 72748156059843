
export default function TitleTween ({ element, wrapper, start = 1, end = 2, startTrigger = '', endTrigger = '' }) {
  if (!element || !wrapper) {
    return
  }

  this.initTween = () => {
    window.GSAP.set(element, { force3D: true }) // We're gonna be needing the GPU real soon
    window.GSAP.fromTo(element, { scaleY: start, transformOrigin: 'bottom center' }, { scaleY: end, transformOrigin: 'bottom center', scrollTrigger: { end: endTrigger, start: startTrigger, trigger: wrapper, scrub: 0 }, ease: 'linear' })
  }

  window.addEventListener('scrolltrigger-loaded', () => this.initTween())
  window.addEventListener('resize', () => window.requestAnimationFrame(() => this.initTween()))
}
