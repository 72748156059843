
// import setAllExternalLinksToTargetBlank from './utilities/external-links-target-blank.js'
import konamiCode from './utilities/konami-code.js'

// Import in reverse order from stylesheets\
require('../../components/structures/cookie-bar/cookie-bar.js')
require('../../components/structures/document/document.js')
require('../../components/structures/skip-to-main/skip-to-main.js')
require('../../components/organisms/animate-section/animate-section.js')
require('../../components/organisms/article-cookies/article-cookies.js')
require('../../components/organisms/content-expander/content-expander.js')
require('../../components/organisms/full-screen-logo/full-screen-logo.js')
require('../../components/organisms/hero-text/hero-text.js')
require('../../components/organisms/list-expander/list-expander.js')
require('../../components/organisms/object-detail-intro/object-detail-intro.js')
require('../../components/organisms/places-map/places-map.js')
require('../../components/organisms/relevant-cards/relevant-cards.js')
require('../../components/molecules/form/form.js')
require('../../components/molecules/menu-bar/menu-bar.js')
require('../../components/molecules/multiple-object-viewers/multiple-object-viewers.js')
require('../../components/molecules/multiple-panorama-viewers/multiple-panorama-viewers.js')
require('../../components/atoms/logo/logo.js')
require('../../components/atoms/object-viewer/object-viewer.js')
require('../../components/atoms/panorama-viewer/panorama-viewer.js')
require('../../components/atoms/picture/picture.js')
require('../../components/atoms/thumbnail-list/thumbnail-list.js')
require('../../components/atoms/video-embed/video-embed.js')

console.log('\n %cMade with %c♥%c by Fabrique \n', 'font: 16px serif;', 'font: 13px serif; color: #f00;', 'font: 16px serif;')

// There are 4 load events:
// - 'init-immediate': (compiles when the script is loaded; blocks rendering)
// - 'init-load': (on DOMContentLoaded event; does not block rendering)
// - 'init-after-load': (on Load event, slightly after DOMContentLoaded)
// - 'init-delayed-load': (after Load event, with a slight delay, for iframes and such)
// Usually, the 'init-load' event will suffice.
window.dispatchEvent(new CustomEvent('init-immediate'))
window.addEventListener('DOMContentLoaded', () => window.dispatchEvent(new CustomEvent('init-load')))
window.addEventListener('load', () => window.dispatchEvent(new CustomEvent('init-after-load')))
window.addEventListener('load', () => window.setTimeout(() => window.dispatchEvent(new CustomEvent('init-delayed-load')), 1000))

// Focus stuff
document.addEventListener('keydown', () => document.documentElement.classList.add('key-pressed'))
document.addEventListener('mousedown', () => document.documentElement.classList.remove('key-pressed'))
document.addEventListener('touchstart', () => document.documentElement.classList.remove('key-pressed'))

// This gives you vh-like values, but without scrollbars and addressbar and such
// This allows usage of document height / width in CSS
// NOTE: Only turn this on when needed - very heavy when resizing
// NOTE: Resize observer only from Safari 13.1 onwards, so back to window.onresize.
// new ResizeObserver(() => {
// ...
// }).observe(document.documentElement)
/*
if (document.documentElement.classList.contains('is-ios') || document.documentElement.classList.contains('is-android')) {
  const onResizeHandler = () => {
    window.requestAnimationFrame(() => {
      document.documentElement.style.setProperty('--100vw', `${document.documentElement.clientWidth}px`)
      document.documentElement.style.setProperty('--100vh', `${document.documentElement.clientHeight}px`)
      document.documentElement.style.setProperty('--100dw', `${document.documentElement.offsetWidth}px`)
      document.documentElement.style.setProperty('--100dh', `${document.documentElement.offsetHeight}px`)
    })
  }
  window.addEventListener('resize', () => onResizeHandler())
  onResizeHandler()
}
*/

window.addEventListener('load', () => document.documentElement.classList.add('has-loaded'))

konamiCode(() => {
  document.documentElement.classList.add('konami-code')
  window.KONAMI_ACTIVATED = true
  window.dispatchEvent(new Event('resize'))
})
