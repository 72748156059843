import { createFocusTrap } from 'focus-trap'
import Component from '../../../assets/scripts/modules/component'

const CAMERA_FOV_DEGREES = 45 // TODO: Should this be dynamic?
const CAMERA_NEAR_PLANE = 1
const CAMERA_FAR_PLANE = 2000
const CAMERA_INITIAL_Z_DISTANCE = 2000

// const SCENE_BACKGROUND_COLOR = 0xFFEBEBEB

const POINT_LIGHT_COLOR = 0xffffff
const POINT_LIGHT_ALPHA = 0.8

const AMBIENT_LIGHT_COLOR = 0xffffff
const AMBIENT_LIGHT_ALPHA = 0.4

const RENDER_FPS = 60

const MAX_AUTOROTATE_LENGTH_MS = 3000

class ObjectViewer extends Component {
  init () {
    this.container = this.element.querySelector('.object-viewer__container')
    this.progress = this.element.querySelector('.object-viewer__progress')
    this.title = this.element.querySelector('.object-viewer__title')
    this.buttonInfo = this.element.querySelector('.button--info')
    this.buttonClose = this.element.querySelector('.button--close')
    this.model = this.element.getAttribute('data-model')
    this.materials = this.element.getAttribute('data-materials')
    this.texturesPath = this.element.getAttribute('data-textures-path')
    this.lazyload = this.element.getAttribute('data-lazyload')

    if (!this.container || !this.model) {
      return
    }

    this.camera = null
    this.scene = null
    this.renderer = null
    this.controls = null
    this.modelData = null
    this.materialsData = null
    this.paused = true
    this.started = false
    this.autoRotateTimeout = null

    this.focusTrap = createFocusTrap(this.container.closest('.object-viewer'))

    this.start()
  }

  start () {
    if (this.started) {
      return
    }

    if (!this.lazyload) {
      this.initViewer()
      this.focusTrap.activate()
      // this.controls?.listenToKeyEvents(window)

      return
    }

    const onStartHandler = () => {
      if (this.started) {
        onResumeHandler()
        return
      }

      this.initViewer()
      this.focusTrap.activate()
      // this.controls?.listenToKeyEvents(window)

      this.started = true
      this.paused = false
      this.render()
    }

    const onResetHandler = () => {
      this.controls?.reset()
      // this.controls?.stopListenToKeyEvents()
      this.focusTrap.deactivate()
      this.paused = true
      this.render()
      document.documentElement.classList.remove('prevent-scrolling')
      document.documentElement.classList.remove('is-fullscreen')
    }

    const onPauseHandler = () => {
      // this.controls?.stopListenToKeyEvents()
      this.focusTrap.deactivate()
      this.paused = true
      this.render()
    }

    const onResumeHandler = () => {
      if (!this.started) {
        onStartHandler()
        return
      }

      this.focusTrap.activate()
      // this.controls?.listenToKeyEvents(window)
      this.paused = false
      this.render()
    }

    const onMouseDownHandler = () => {
      this.element.classList.add('object-viewer--grabbing')
      this.buttonInfo?.classList.remove('button--visible')
      this.title?.classList.remove('object-viewer__title--visible')
    }

    const onMouseUpHandler = () => {
      this.element.classList.remove('object-viewer--grabbing')
    }

    const onTouchStartHandler = () => {
      this.element.classList.add('object-viewer--grabbing')
      this.buttonInfo?.classList.remove('button--visible')
      this.title?.classList.remove('object-viewer__title--visible')
    }

    const onTouchEndHandler = () => {
      this.element.classList.remove('object-viewer--grabbing')
    }

    const onDoubleClickHandler = () => {
      this.zoomCameraToSelection()
    }

    this.element.addEventListener('start', onStartHandler)
    this.element.addEventListener('reset', onResetHandler)
    this.element.addEventListener('pause', onPauseHandler)
    this.element.addEventListener('resume', onResumeHandler)
    this.element.addEventListener('mousedown', onMouseDownHandler)
    this.element.addEventListener('mouseup', onMouseUpHandler)
    this.element.addEventListener('touchstart', onTouchStartHandler)
    this.element.addEventListener('touchend', onTouchEndHandler)
    this.element.addEventListener('dblclick', onDoubleClickHandler)
  }

  async initViewer () {
    await this.setupCamera()
    await this.setupScene()
    await this.loadMaterials()
    await this.loadObject()
    await this.setupRenderer()
    await this.setupOrbitControls()

    window.addEventListener('resize', () => this.onResizeHandler())
    this.onResizeHandler()
    this.initAutoRotate()

    await this.startRender()

    this.buttonInfo?.classList.add('button--visible')
    this.title?.classList.add('object-viewer__title--visible')

    if (!this.element.classList.contains('object-viewer--fullscreen')) {
      return
    }

    this.buttonClose?.addEventListener('click', () => this.onButtonCloseClickHandler())

    document.onkeydown = () => this.onDocumentKeydownHandler()
    // document.addEventListener('fullscreenchange', () => this.onResizeHandler())
  }

  onDocumentKeydownHandler () {
    if (!this.started || this.paused || !event.key) {
      return
    }

    if (event.key === 'Escape' || event.key === 'Esc') {
      this.onButtonCloseClickHandler()
    }
  }

  onButtonCloseClickHandler () {
    if (this.element.classList.contains('object-viewer--active')) {
      this.closeViewer()
    } else {
      this.openViewer()
    }
  }

  closeViewer () {
    if (!this.element.classList.contains('object-viewer--active')) {
      return
    }

    this.element.classList.remove('object-viewer--active')
    document.documentElement.classList.remove('prevent-scrolling')
    document.documentElement.classList.remove('is-fullscreen')
    this.focusTrap.deactivate()
  }

  openViewer () {
    if (this.element.classList.contains('object-viewer--active')) {
      return
    }

    this.element.classList.add('object-this.element--active')
    document.documentElement.classList.add('prevent-scrolling')
    document.documentElement.classList.add('is-fullscreen')
    this.focusTrap.activate()
  }

  async setupCamera () {
    const { PerspectiveCamera, PointLight } = (await import('../../../assets/scripts/plugins/three-js')).default()

    this.camera = new PerspectiveCamera(CAMERA_FOV_DEGREES, this.containerWidth / this.containerHeight, CAMERA_NEAR_PLANE, CAMERA_FAR_PLANE)
    this.camera.position.set(0, 0, CAMERA_INITIAL_Z_DISTANCE)
    this.camera.up.set(0, 0, 1) // TRY TO CONSTRAIN Z AXIS -EKL

    // this.camera.position.x = 10
    // this.camera.position.y = -200
    // this.camera.position.z = 150
    this.camera.add(new PointLight(POINT_LIGHT_COLOR, POINT_LIGHT_ALPHA))
  }

  async setupScene () {
    const { AmbientLight, Scene } = (await import('../../../assets/scripts/plugins/three-js')).default()

    this.scene = new Scene()
    // this.scene.background = new Color(SCENE_BACKGROUND_COLOR)
    this.scene.add(new AmbientLight(AMBIENT_LIGHT_COLOR, AMBIENT_LIGHT_ALPHA))
    this.scene.add(this.camera)
  }

  async loadMaterials () {
    if (!this.materials) {
      return
    }

    const { MTLLoader } = (await import('../../../assets/scripts/plugins/three-js')).default()
    const loader = new MTLLoader()

    this.materialsData = await loader.loadAsync(this.materials)
    this.onLoadMTLHandler()
  }

  async loadObject () {
    const { OBJLoader } = (await import('../../../assets/scripts/plugins/three-js')).default()
    const loader = new OBJLoader()

    if (this.materialsData) {
      loader.setMaterials(this.materialsData)
    }

    this.modelData = await loader.loadAsync(this.model, (xhr) => this.updateProgressHandler(xhr))
    this.onLoadOBJHandler()
  }

  async setupRenderer () {
    const { WebGLRenderer, sRGBEncoding } = (await import('../../../assets/scripts/plugins/three-js')).default()

    this.renderer = new WebGLRenderer({ antialias: true, alpha: true })

    // Lighting system has changed in v155, so we use the legacy one for now. Will be removed in v165.
    this.renderer.useLegacyLights = true

    // Output encoding has changed in v151, so this is deprecated. Will be removed in v161.
    this.renderer.outputEncoding = sRGBEncoding

    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(this.containerWidth, this.containerHeight)
    this.renderer.autoClear = false
    this.renderer.setClearColor(0x000000, 0.0)

    this.container.appendChild(this.renderer.domElement)
  }

  async setupOrbitControls () {
    const { CustomOrbitControls } = (await import('../../../assets/scripts/plugins/three-js')).default()

    this.controls = new CustomOrbitControls(this.camera, this.renderer.domElement)

    this.controls.enabled = true
    this.controls.minDistance = 150
    this.controls.maxDistance = 750
    this.controls.enableDamping = true
    this.controls.dampingFactor = 0.25
    this.controls.enableZoom = true
    this.controls.zoomSpeed = 1.0
    this.controls.enableRotate = true
    this.controls.rotateSpeed = 1.0
    this.controls.enablePan = false
    this.controls.panSpeed = 1.0
    this.controls.screenSpacePanning = false
    this.controls.keyPanSpeed = 7.0 // pixels moved per arrow key push
    this.controls.autoRotate = false
    this.controls.autoRotateSpeed = 2.0 // 30 seconds per round when fps is 60
    this.controls.enableKeys = true

    // this.controls.maxPolarAngle = ?
    // this.controls.maxAzimuthAngle = ?

    this.controls.listenToKeyEvents(this.element)
    // this.controls.stopListenToKeyEvents()

    this.controls.update()
  }

  updateProgressHandler (xhr) {
    if (!this.progress || !xhr.lengthComputable) {
      return
    }

    window.requestAnimationFrame(() => {
      const progress = xhr.loaded !== xhr.total ? Math.round((xhr.loaded / xhr.total) * 100, 2) + '%' : ''
      this.progress.innerText = progress
    })
  }

  async onLoadMTLHandler () {
    await this.materialsData.preload()
  }

  async onLoadOBJHandler () {
    // this.modelData.position.x = 0
    // this.modelData.position.y = 0
    // this.modelData.position.z = 0

    this.scene.add(this.modelData)
  }

  async onResizeHandler () {
    if (!this.container || !this.container.clientWidth || !this.container.clientHeight) {
      return
    }

    this.containerWidth = this.container.clientWidth
    this.containerHeight = this.container.clientHeight

    if (this.camera) {
      this.camera.aspect = this.containerWidth / this.containerHeight
      this.camera.updateProjectionMatrix()
    }

    if (this.renderer) {
      this.renderer.setSize(this.containerWidth, this.containerHeight)
    }

    await this.zoomCameraToSelection()
  }

  async startRender () {
    this.fpsInterval = 1000 / RENDER_FPS
    this.timeThen = window.performance.now()
    this.startTime = this.timeThen
    this.paused = false

    await this.zoomCameraToSelection()

    this.render()
  }

  render (timeNew = window.performance.now()) {
    if (this.paused) {
      return
    }

    window.requestAnimationFrame((timeNew) => this.render(timeNew))

    const timeNow = timeNew
    const timeElapsed = timeNow - this.timeThen

    if (timeElapsed > this.fpsInterval) {
      this.timeThen = timeNow - (timeElapsed % this.fpsInterval)

      this.camera.lookAt(this.scene.position)
      this.controls.update()
      this.renderer.render(this.scene, this.camera)
    }
  }

  initAutoRotate () {
    this.controls.autoRotate = true

    window.clearTimeout(this.autoRotateTimeout)
    this.autoRotateTimeout = window.setTimeout(() => this.stopAutoRotate(), MAX_AUTOROTATE_LENGTH_MS)

    this.element.addEventListener('mousedown', () => this.stopAutoRotate(), false)
    this.element.addEventListener('touchstart', () => this.stopAutoRotate(), false)
    this.element.addEventListener('keydown', () => this.stopAutoRotate(), false)
  }

  stopAutoRotate () {
    this.controls.autoRotateSpeed -= 0.01

    if (this.controls.autoRotateSpeed < 0.02) {
      this.controls.autoRotate = false
    } else {
      window.setTimeout(() => this.stopAutoRotate(), 15)
    }
  }

  async zoomCameraToSelection (fitRatio = 1.2) {
    const { Box3, Vector3 } = (await import('../../../assets/scripts/plugins/three-js')).default()

    if (!this.camera || !this.controls) {
      return
    }

    const selection = this.scene.children.filter((child) => child.type === 'Group')

    if (!selection) {
      return
    }

    const box = new Box3()

    for (const object of selection) {
      box.expandByObject(object)
    }

    const zoomFactor = 1.25 // > 1 === more padding
    const size = box.getSize(new Vector3())
    const center = box.getCenter(new Vector3())
    const maxSize = Math.max(size.x * zoomFactor, size.y * zoomFactor, size.z * zoomFactor)
    const fitHeightDistance = maxSize / (2 * Math.atan((Math.PI * this.camera.fov) / 360))
    const fitWidthDistance = fitHeightDistance / this.camera.aspect
    const distance = fitRatio * Math.max(fitHeightDistance, fitWidthDistance)
    const direction = this.controls.target.clone().sub(this.camera.position).normalize().multiplyScalar(distance)

    this.controls.minDistance = distance / 3
    this.controls.maxDistance = distance * 5
    this.controls.target.copy(center)

    this.camera.near = distance / 100
    this.camera.far = distance * 100
    this.camera.updateProjectionMatrix()

    this.camera.position.copy(this.controls.target).sub(direction)

    this.controls.update()
    this.controls.saveState()
  }
}

window.addEventListener('init-after-load', () => document.querySelectorAll('.object-viewer').forEach(element => {
  element.instance = element.instance || new ObjectViewer(element)
}))
